import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { HTMLRichTextSerializer } from '@prismicio/client';
import { GlobalContent } from '../../../services/global-content.service';
import { LinkComponent } from '../link/link.component';
import { RenderHtmlPipe } from '../prismic/render-html/render-html.pipe';
import { getButtonClass } from '../utils/utils';

@Component({
    selector: 'app-footer',
    standalone: true,
    imports: [CommonModule, LinkComponent, RenderHtmlPipe, RouterLink],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss'
})
export class FooterComponent {
    @Input({ required: true }) globalContent!: GlobalContent;

    year = new Date().getFullYear();
    private paragraphCount = 0;

    htmlSerializer: HTMLRichTextSerializer = {
        heading2: ({ children }) => '<h2 class="text-size-xl">' + children + '<h2/>',
        paragraph: ({ children }) => {
            const isLastParagraph = this.paragraphCount === this.globalContent?.footer_title?.length - 1;
            const additionalClass = isLastParagraph ? 'text-size-m' : 'text-size-m mb-0';

            this.paragraphCount++;

            return `<p class="${additionalClass}">${children}</p>`;
        }
    };
    protected readonly getButtonClass = getButtonClass;
}
