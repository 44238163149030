import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
registerLocaleData(localeDe);

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScullyLibModule } from '@scullyio/ng-lib';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { NavigationComponent } from './components/shared/navigation/navigation.component';
import {TopbarComponent} from "./components/shared/topbar/topbar.component";

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, AppRoutingModule, NavigationComponent, FooterComponent, ScullyLibModule.forRoot(), BrowserAnimationsModule, TopbarComponent],
    providers: [
        BsModalService,
        {
            provide: LOCALE_ID,
            useValue: 'de-DE' // 'de-DE' for Germany, 'fr-FR' for France ...
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
