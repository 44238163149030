import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { RenderHtmlPipe } from '../prismic/render-html/render-html.pipe';
import { descriptionSerializer, descriptionSmallSerializer, textSizeMSerializer, titleSerializer } from '../prismic/richtextSerializer';
import { ModalActionService } from './modal-action.service';

@Component({
    selector: 'app-modal',
    standalone: true,
    imports: [CommonModule, RenderHtmlPipe, ReactiveFormsModule],
    templateUrl: './modal.component.html',
    styleUrl: './modal.component.scss'
})
export class ModalComponent implements OnInit {
    data?: any;
    formGroup?: FormGroup;
    private formBuilder = inject(FormBuilder);
    private modalActionService = inject(ModalActionService);
    public bsModalRef = inject(BsModalRef);
    protected readonly titleSerializer = titleSerializer;
    protected readonly textSizeMSerializer = textSizeMSerializer;
    status = Status.Default;
    Status = Status;
    blockSurvey: boolean = false;

    ngOnInit() {
        this.formGroup = this.formBuilder.group({
            answer: new FormControl('', [Validators.required]),
            customAnswer: new FormControl('')
        });

        if (localStorage.getItem('survey-participated')) {
            this.blockSurvey = true;
        }
    }

    submit(surveyId: string) {
        let answer;
        if (this.formGroup?.controls['customAnswer'].value && this.formGroup?.controls['answer'].value === 'custom') {
            answer = this.formGroup?.controls['customAnswer'].value;
        } else {
            answer = this.formGroup?.controls['answer'].value;
        }

        this.modalActionService.submitSurvey(surveyId, answer).subscribe({
            next: (res) => {
                this.status = Status.Success;
                localStorage.setItem('survey-participated', 'true');
            },
            error: (err) => {
                this.status = Status.Error;
            }
        });
    }

    protected readonly descriptionSmallSerializer = descriptionSmallSerializer;
    protected readonly descriptionSerializer = descriptionSerializer;
}

enum Status {
    Default,
    Pending,
    Error,
    Success
}
