<footer class="mt-5 mt-lg-6">
    <img src="./assets/images/rectangle.svg" alt="" class="w-100" />
    <div class="background-green pb-5">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-12 mt-5 mt-lg-0 text-center">
                    <div [innerHTML]="globalContent?.footer_contact_title | renderHtml: htmlSerializer"></div>
                    <div [innerHTML]="globalContent?.footer_contact_description | renderHtml"></div>
                </div>
                <div class="col-auto mt-4 mt-lg-5" *ngFor="let action of globalContent?.footer_contact_actions">
                    <app-link [link]="action.link" [cssClass]="getButtonClass(action.style)">
                        {{ action.title }}
                    </app-link>
                </div>
            </div>
            <hr class="my-5 my-lg-6" />
            <div class="row mt-5 d-flex justify-content-between">
                <div class="col-lg-6 pe-lg-6 mb-5 mb-lg-0">
                    <div [innerHTML]="globalContent?.footer_title | renderHtml: htmlSerializer"></div>
                    <div [innerHTML]="globalContent?.footer_description | renderHtml"></div>
                    <app-link [link]="globalContent?.footer_button_link" cssClass="btn btn-black-filled">{{ globalContent?.footer_button_text }}</app-link>
                </div>
                <div class="col-lg-3 mb-5">
                    <h3>{{ globalContent?.footer_links_title }}</h3>
                    <ul class="mt-5">
                        <li *ngFor="let link of globalContent?.footer_links">
                            <app-link [link]="link.link">
                                {{ link.title }}
                            </app-link>
                        </li>
                    </ul>
                </div>
                <div class="col-lg-3 mb-5">
                    <h3>Kontakt</h3>
                    <div class="my-5">
                        <p>{{ globalContent?.footer_contact_address }}</p>
                        <a class="color-black" [href]="'mailto:' + globalContent?.footer_contact_email">{{ globalContent?.footer_contact_email }}</a>
                    </div>
                    <ul>
                        <li *ngFor="let link of globalContent?.footer_links_legal">
                            <app-link [link]="link.link">
                                {{ link.title }}
                            </app-link>
                        </li>
                    </ul>
                </div>
            </div>
            <hr />
            <div class="row justify-content-center justify-content-lg-between mt-5">
                <div class="col-10 col-md-auto text-center text-md-start">
                    <p>© {{ year }} Ralphy’s Ranch – Tierauffangstation & Lebenshof e.V.</p>
                </div>
                <div class="col-md text-center text-md-end">
                    <div>
                        @for (social of globalContent?.footer_social_links; track social; let last = $last) {
                            <app-link [link]="social?.link">
                                <img class="icon-small" [class.me-3]="!last" [src]="social?.icon?.url" [alt]="social?.icon?.alt" />
                            </app-link>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
