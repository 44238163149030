import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./components/standard/standard.routing').then((mod) => mod.STANDARD_ROUTES),
        data: {
            uid: 'startseite'
        }
    },
    {
        path: 'seite-nicht-gefunden',
        loadChildren: () => import('./components/not-found/not-found.routing').then((mod) => mod.NOT_FOUND_ROUTES)
    },
    {
        path: 'tiere',
        loadChildren: () => import('./components/animal/animal.routing').then((mod) => mod.ANIMAL_ROUTES)
    },
    {
        path: 'blog',
        loadChildren: () => import('./components/blog/blog.routing').then((mod) => mod.BLOG_ROUTES)
    },
    {
        path: 'hilfe',
        loadChildren: () => import('./components/help/help.routing').then((mod) => mod.HELP_ROUTES)
    },
    {
        path: 'patenschaft',
        loadChildren: () => import('./components/partnership/partnership.routing').then((mod) => mod.PARTNERSHIP_ROUTES)
    },
    {
        path: 'preview',
        loadChildren: () => import('./components/preview/preview.routing').then((mod) => mod.PREVIEW_ROUTES)
    },
    {
        path: ':uid',
        loadChildren: () => import('./components/standard/standard.routing').then((mod) => mod.STANDARD_ROUTES)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'reload',
            initialNavigation: 'enabledBlocking',
            anchorScrolling: 'enabled'
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {}
