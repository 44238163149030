export function getMargin(body: any) {
    return {
        'mt-5 mt-lg-6': body?.primary.margin_top,
        'mb-5 mb-lg-6': body?.primary.margin_bottom
    };
}

export function getButtonClass(style: string): string {
    switch (style) {
        case 'Link':
            return 'btn';
        case 'Button (Primär)':
            return 'btn btn-green-filled';
        case 'Button (schwarz)':
            return 'btn btn-black-filled';
        case 'Button (Outline)':
            return 'btn btn-black-outline';
        default:
            return 'btn';
    }
}
