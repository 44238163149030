import { RTLinkNode } from '@prismicio/client';
import { PrismicDocument } from '@prismicio/types';
import { getPrismicUids, resolveDocumentIds } from './prismic-uids';
import { DEFAULT_EXTRA_OPTIONS, PrismicRoute, RouteConfig } from './routes.model';

enum ScullyEventNames {
    ANGULAR_INITIALIZED = 'AngularInitialized',
    ANGULAR_READY = 'AngularReady',
    ANGULAR_TIMEOUT = 'AngularTimeout',
    FLASH_PREVENTION_SWTICH_DONE = 'FlashPreventionSwitchDone'
}

export function removeFlashPreventionOverlay(): void {
    window.dispatchEvent(
        new Event(ScullyEventNames.ANGULAR_READY, {
            bubbles: true,
            cancelable: false
        })
    );
    const script = document.getElementById('scully-plugin-discount-flash-prevention');
    if (script) {
        script.remove();
    }
}

/**
 * This function might be useful to interact with DOM elements right after the scully
 * overlay has been removed from the app. For example, you need to do this to measure
 * the dimensions of HTML elements in your `ngAfterViewInit`.
 */
export function onScullyOverlayRemoved(cb: () => void): void {
    window.addEventListener(ScullyEventNames.FLASH_PREVENTION_SWTICH_DONE, cb, {
        once: true
    });
}

export function serializeHyperlink(children: string, node: RTLinkNode, linkStyle: 'green' | 'black' = 'green') {
    let link;
    const { link_type, url } = node.data;
    switch (link_type) {
        case 'Document': {
            const { type, uid } = node.data;
            switch (type) {
                case 'standard-page':
                    link = `/${uid!.replace('startseite', '')}`;
                    break;
                case 'blog':
                    link = `/blog/${uid}`;
                    break;
                case 'animal':
                    link = `/tiere/${uid}`;
                    break;
                case 'help':
                    link = `/hilfe/`;
                    break;
                case 'help_category':
                    link = `/hilfe/${uid}`;
                    break;
                case 'help_article':
                    link = `/hilfe/${uid?.replace('.', '/')}`;
            }
            break;
        }
        default:
            link = url;
    }

    if (linkStyle === 'black') {
        return `<a href="${link}" class="text-decoration-underline color-black underline-offset fw-bold">${children}</a>`;
    } else {
        return `<a href="${link}" class="text-decoration-underline color-green">${children}</a>`;
    }
}

export async function getRoutes(config: RouteConfig, options = DEFAULT_EXTRA_OPTIONS): Promise<PrismicRoute[]> {
    console.log('Starting to collect routes\n');
    config.includeDocumentData = config.includeDocumentData || false;

    const prismicRoutes: PrismicRoute[] = [];

    for (const docTypeConfig of config.docTypeConfigs) {
        let documents: PrismicDocument[] = [];
        if (Array.isArray(config.documentIds) && config.documentIds.length) {
            const resolved = await resolveDocumentIds({
                repositoryName: config.repositoryName,
                documentIds: config.documentIds,
                includeDocumentData: config.includeDocumentData,
                additionalConfig: config.additionalConfig
            });
            documents = resolved.filter((doc) => doc.type === docTypeConfig.documentType);

            if (documents.length) {
                console.log(`Resolved ${documents.length} document(s) by their ids:`);
                for (const document of documents) {
                    console.log(`${document.id} => ${document.uid}`);
                }
            } else {
                console.log(`Did not find any documents of type "${docTypeConfig.documentType}" with the provided ids: ${JSON.stringify(config.documentIds)}`);
            }
        } else {
            documents = await getPrismicUids(config.repositoryName, docTypeConfig.documentType, config.includeDocumentData, config.additionalConfig);
        }
        const mappedRoutes = documents.map((doc) => {
            let resolvedRouteOrRoutes = docTypeConfig.uidMappingFunc(doc.uid, doc);
            if (!Array.isArray(resolvedRouteOrRoutes)) {
                resolvedRouteOrRoutes = [resolvedRouteOrRoutes];
            }

            const resolvedRoutes: PrismicRoute[] = resolvedRouteOrRoutes.map((route) => ({
                route,
                doc
            }));

            return resolvedRoutes;
        });
        const flattenedMappedRoutes = flatten(mappedRoutes);
        prismicRoutes.push(...flattenedMappedRoutes);
        console.log(`Found ${mappedRoutes.length} routes for document type "${docTypeConfig.documentType}"`);
    }

    console.log(`Found ${prismicRoutes.length} total routes\n`);
    return Promise.resolve(prismicRoutes);
}

/**
 * Flattens an array up to the specified depth.
 *
 * Use recursion, decrementing depth by 1 for each level of depth.
 * Use Array.prototype.reduce() and Array.prototype.concat() to merge
 * elements or arrays. Base case, for depth equal to 1 stops recursion.
 * Omit the second argument, depth to flatten only to a depth of 1 (single flatten).
 *
 * From: https://30secondsofcode.org/#flatten
 */
function flatten(arr: any, depth = 1) {
    return arr.reduce((a: any, v: any) => a.concat(depth > 1 && Array.isArray(v) ? flatten(v, depth - 1) : v), []);
}
