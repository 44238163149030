import { Injectable } from '@angular/core';
import { PrismicDocument, RichTextField, SliceZone } from '@prismicio/client';
import { Observable, from } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ModalService {
    getModal(id: string): Observable<PrismicDocument<Modal>> {
        const previewValue = sessionStorage.getItem('preview');
        const ref = previewValue ? { ref: previewValue } : {};
        return from(
            environment.prismic.client.getByID<PrismicDocument<Modal>>(id, {
                ...ref
            })
        );
    }
}

export type Modal = {
    title: RichTextField;
    description: RichTextField;
    body?: SliceZone<any>;
};
