@if (environment?.name === 'DEV' && !environment.production) {
    <app-topbar [environmentName]="environment?.name"></app-topbar>
}
@if (!isDonationPage()) {
    <app-navigation [globalContent]="globalContent"></app-navigation>
}
<router-outlet></router-outlet>
@if (!isDonationPage()) {
    <app-footer [globalContent]="globalContent"></app-footer>
}
