<div class="container px-3 py-4 p-lg-5">
    <div class="row d-flex align-items-center">
        <div class="col">
            <p class="text-size-l mb-2">{{ data?.data?.title }}</p>
        </div>
        <div class="col d-flex justify-content-end align-items-start">
            <button class="btn-unstyle" (click)="bsModalRef?.hide()">
                <img class="icon-small" src="assets/icons/close.svg" />
            </button>
        </div>
    </div>
    <div class="row">
        <div class="col">
            @if (status === Status.Default && !blockSurvey) {
                <div [innerHTML]="data?.data?.description | renderHtml: descriptionSerializer"></div>
            }
        </div>
    </div>
    @if (blockSurvey) {
        <div class="row">
            <div class="col-12 d-flex justify-content-center align-items-center flex-column py-5">
                <img src="assets/icons/check-filled.svg" class="icon-big" />
                <p class="py-5 text-size-m">Danke, dass du bereits abgestimmt hast.</p>
                <button class="btn btn-green-filled" (click)="bsModalRef?.hide()">Schließen</button>
            </div>
        </div>
    } @else if (status === Status.Success) {
        <div class="row">
            <div class="col-12 d-flex justify-content-center align-items-center flex-column py-5">
                <img src="assets/icons/check-filled.svg" class="icon-big" />
                <p class="py-5 text-size-m">Danke für deine Abstimmung. Du kannst das Fenster jetzt schließen.</p>
                <button class="btn btn-green-filled" (click)="bsModalRef?.hide()">Schließen</button>
            </div>
        </div>
    } @else if (status === Status.Error) {
        <div class="row">
            <div class="col-12 d-flex justify-content-center align-items-center flex-column py-5">
                <img src="assets/icons/remove.svg" class="icon-big" />
                <p class="py-5">Abstimmung fehlgeschlagen. Bitte versuche es später erneut.</p>
            </div>
        </div>
    } @else {
        @for (o of data?.data?.body; track $index) {
            <form [formGroup]="formGroup">
                @if (o?.slice_type === 'survey') {
                    @for (a of o?.items; track $index) {
                        <div class="row mt-3">
                            <div class="col-auto pt-3">
                                <input type="radio" [id]="a?.technical_name" name="answer" [value]="a?.technical_name" formControlName="answer" />
                            </div>
                            <div class="col ps-3">
                                <label [for]="a?.technical_name">
                                    <p class="text-size-m mb-1">
                                        {{ a?.option_title }}
                                    </p>
                                    <div [innerHTML]="a?.option_description | renderHtml: descriptionSmallSerializer"></div>
                                </label>
                            </div>
                        </div>
                    }
                    <div class="row mt-3">
                        <div class="col-auto pt-1">
                            <input #custom type="radio" id="custom" name="answer" value="custom" formControlName="answer" />
                        </div>
                        <div class="col ps-3">
                            <label class="text-size-m" for="custom">Sonstiges</label><br />
                            @if (custom.checked) {
                                <input class="form-control" formControlName="customAnswer" type="text" placeholder="Was wünschst du dir?" />
                            }
                        </div>
                    </div>
                    <button [disabled]="formGroup?.invalid && !formGroup?.get('customAnswer')?.value" (click)="submit(o?.primary?.id)" class="btn btn-green-filled mt-5">Abstimmen</button>
                }
            </form>
        }
    }
</div>
