import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ModalActionService {
    constructor(private http: HttpClient) {}

    submitSurvey(surveyId: string, answer: string): Observable<void> {
        return this.http.post<void>(environment.backend + `/surveys/${surveyId}`, {
            answer
        });
    }
}
