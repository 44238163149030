import { Pipe, PipeTransform } from '@angular/core';
import { RichTextField, asHTML } from '@prismicio/client';
import { HTMLRichTextMapSerializer } from '@prismicio/client/dist/helpers/asHTML';
import { HTMLRichTextFunctionSerializer } from '@prismicio/client/src/helpers/asHTML';

@Pipe({
    name: 'renderHtml',
    standalone: true
})
export class RenderHtmlPipe implements PipeTransform {
    transform(value: RichTextField, serializer?: HTMLRichTextMapSerializer | HTMLRichTextFunctionSerializer): unknown {
        return asHTML(value, undefined, serializer);
    }
}
